import { FC } from 'react';

import { Box, useTheme } from '@mui/material';

import Icon from 'components/Icons/Icon';
import {
  TicketClinicianMessageStatusErrorReason,
  TicketClinicianMessageStatusType,
  TicketClinicianMessageType
} from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessage.types';

import { TextButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  message: TicketClinicianMessageType;
  onRetryMessage: (messageId: number) => void;
  isLoading: boolean;
}

export const TicketClinicianMessageStatus: FC<Props> = ({ message, onRetryMessage, isLoading }) => {
  const { palette } = useTheme();
  const isOptedOutOfSms = message.errorReason === TicketClinicianMessageStatusErrorReason.OptedOut;

  return (
    <>
      {message.status === TicketClinicianMessageStatusType.Sent && (
        <Box display="flex" alignItems="center" gap={4}>
          <Icon.CheckMark color={palette.text.secondary} />

          <Text variant="body2" color="secondary">
            Delivered
          </Text>
        </Box>
      )}

      {(message.status === TicketClinicianMessageStatusType.Pending ||
        message.status === TicketClinicianMessageStatusType.Processing) && (
        <Box display="flex">
          <Text variant="body2" color="secondary">
            Sending...
          </Text>
        </Box>
      )}

      {message.status === TicketClinicianMessageStatusType.Failed && (
        <>
          <Box display="flex">
            <Text variant="body2" color={isOptedOutOfSms ? 'error' : 'secondary'}>
              Not Delivered •&nbsp;
            </Text>

            {isOptedOutOfSms ? (
              <Text variant="body2" color="secondary">
                Opted Out of SMS
              </Text>
            ) : (
              <TextButton
                variant="secondary"
                onClick={() => onRetryMessage(message.id)}
                disabled={isLoading}
              >
                Try Again
              </TextButton>
            )}
          </Box>
        </>
      )}
    </>
  );
};
