import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

export interface SendHomeCareInstructionsResponse {
  createdAt: string;
  id: number;
}

export interface SendMessageToPatientBody {
  ticketId: number;
  message: string;
}

export interface MarkTicketMessagesAsSeenBody {
  ids: number[];
}

const httpService = new HttpService('two way messages');

export class TwoWayMessagesFetcher {
  static async retryTicketMessage(messageId: number) {
    return await httpService.patch({
      url: API_URLS.RETRY_TICKET_MESSAGE(messageId),
      transformError: transformErrorUiProps('Failed to retry ticket message')
    });
  }

  static async sendMessageToPatient(body: SendMessageToPatientBody) {
    return await httpService.post({
      url: API_URLS.SEND_MESSAGE,
      data: body,
      transformError: transformErrorUiProps('Failed to send message to patient')
    });
  }

  static async markTicketMessagesAsSeen(body: MarkTicketMessagesAsSeenBody) {
    return await httpService.post({
      url: API_URLS.MARK_TICKET_MESSAGES_AS_SEEN,
      data: body,
      transformError: transformErrorUiProps('Failed to mark ticket messages as seen')
    });
  }
}
