import { FC } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { TicketMessageType } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  senderName: string;
  senderType: TicketMessageType;
}

export const TicketMessageAvatar: FC<Props> = ({ senderName, senderType }) => {
  const names = senderName.split(' ');
  const firstInitial = names[0].charAt(0).toUpperCase();
  const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
  const initials = `${firstInitial}${lastInitial}`;

  return (
    <StyledContainer senderType={senderType}>
      <Text color="white" variant="body2">
        {initials}
      </Text>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'senderType'
})<{ senderType: TicketMessageType }>(
  ({ theme, senderType }) => css`
    width: 32px;
    height: 32px;
    background-color: ${senderType === TicketMessageType.Clinician
      ? theme.palette.secondary.main
      : theme.palette.tag.tag6};
    border-radius: ${theme.borderRadius.full};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  `
);
