import { FC } from 'react';

import { Box } from '@mui/material';

import { formatDate } from 'utils/DateUtils';

import {
  TicketClinicianMessageStatusErrorReason,
  TicketClinicianMessageStatusType,
  TicketClinicianMessageType
} from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessage.types';

import { TicketClinicianMessageStatus } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessageStatus';
import { TicketMessageAvatar } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessageAvatar';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  message: TicketClinicianMessageType;
  onRetryMessage: (messageId: number) => void;
  isLoading: boolean;
}

export const TicketClinicianMessage: FC<Props> = ({ message, onRetryMessage, isLoading }) => {
  const isOptedOutOfSms = message.errorReason === TicketClinicianMessageStatusErrorReason.OptedOut;
  const isPendingOrProcessingStatus =
    message.status === TicketClinicianMessageStatusType.Pending ||
    message.status === TicketClinicianMessageStatusType.Processing;

  function getMessageTextColor() {
    if (isOptedOutOfSms) {
      return 'error';
    }

    if (isPendingOrProcessingStatus) {
      return 'secondary';
    }

    return 'primary';
  }

  return (
    <Box display="flex" flexDirection="column" gap={8}>
      <Box display="flex" alignItems="center" gap={8}>
        <TicketMessageAvatar senderName={message.senderName} senderType={message.senderType} />

        <Box display="flex" flexDirection="column" gap={4}>
          <Text>
            {message.senderName}, {message.credential}{' '}
            <Text color="secondary">{formatDate(message.createdAt, 'MM/DD/YYYY, H:mm A')}</Text>
          </Text>

          <TicketClinicianMessageStatus
            message={message}
            onRetryMessage={onRetryMessage}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Text variant="body2" color={getMessageTextColor()}>
        {message.text}
      </Text>
    </Box>
  );
};
