// @ts-strict-ignore
import { FC, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { observer } from 'mobx-react';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { useTaskTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { getClinicianFullName } from 'utils/ClinicianCredentialUtils';
import { fromNow, FromNowOptions, getDateMonthString, isToday, isYesterday } from 'utils/DateUtils';

import { textWithLineBreaks } from 'utils/StringUtils';

import Ticket from 'models/Ticket';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import Chip from 'components/Chips/Chip';
import { usePatientModel } from 'components/Patient/usePatientModel';
import EpisodeTag from 'components/Ticket/TicketRow/EpisodeTag';
import { TaskStatus } from 'components/Ticket/TicketRow/TaskStatus';
import { TicketCommunicationSection } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection';
import TicketCreatedBy from 'components/Ticket/TicketRow/TicketCreatedBy';
import TicketRenderer from 'components/Ticket/TicketRow/TicketRenderer';
import TicketRowIcon, { TicketRowIconType } from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';
import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

const fromNowOptions: FromNowOptions = {
  fromNowPrefix: 'Due in',
  agoPrefix: 'Due ',
  fromNowSuffix: ''
};

interface TaskRowDueDateIconProps {
  children: ReactNode;
  header?: string;
}

export const TaskRowDueDateIcon: FC<TaskRowDueDateIconProps> = ({ header, children }) => {
  return (
    <TicketRowIcon header={header} type={TicketRowIconType.TASK}>
      {children}
    </TicketRowIcon>
  );
};

interface TaskTicketRowProps {
  ticket: Ticket;
  className?: string;
  withPatientLink: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
  ticketSectionId: TicketSectionId;
}

const TaskTicketRow: FC<TaskTicketRowProps> = ({
  ticket,
  className,
  withPatientLink,
  ticketRefsCallback,
  ticketIndex,
  ticketSectionCurrentPage = 0,
  ticketSectionId
}) => {
  const { constantsStore } = useStores();
  const patient = usePatientModel(ticket.patientId);
  const { ticketActions: Actions, tab } = useTicketOverviewContext();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    true,
    ticketSectionCurrentPage,
    false,
    tab
  );

  const isActionInProgress = useTaskTicketNetworkLoading(ticket.id, ticket.taskTicket.id);

  const { dueDate, name, description, episodeId, roleId, monthNumber, owner } = ticket.taskTicket;
  const episode = constantsStore.getEpisodeById(episodeId) || null;

  const role = constantsStore.getRoleByIdWithAllAndNoRoles(roleId);

  const dueDateStr = useMemo(() => {
    if (!dueDate) {
      return null;
    }

    if (isToday(dueDate)) {
      return 'Due Today: ';
    }

    if (isYesterday(dueDate)) {
      return 'Due Yesterday: ';
    }

    return `${fromNow(new Date(dueDate), fromNowOptions)}: `;
  }, [dueDate]);

  const summary = (
    <div>
      <div className="title-text">
        {dueDateStr}
        {name}
      </div>
      <Chip.List classes="mt-1">
        <EpisodeTag episode={episode} />
        {owner && <Chip.Item className="mb-2">Owner: {getClinicianFullName(owner)}</Chip.Item>}
        {role && <Chip.Item className="mb-2">Role: {role.name}</Chip.Item>}
        {monthNumber && <Chip.Item className="mb-2">Month: {monthNumber}</Chip.Item>}
      </Chip.List>
      {description && <SanitizeHtml html={textWithLineBreaks(description)} />}

      <TicketSummaryButton
        isExpanded={isExpanded}
        onClick={toggle}
        items={{ comment: ticket?.commentCount }}
      />
    </div>
  );

  const classes = {
    container: classNames(className),
    actions: classNames('d-flex align-items-baseline')
  };

  return (
    <TicketRenderer
      testHook={ticketSelectors.container(ticket.id, TicketType.Task, ticketSectionId)}
      ticketRefsCallback={ticketRefsCallback}
      isLoading={isActionInProgress}
      ticketId={ticket.id}
      hideBulk={ticket.isClosedOrResolved}
      patient={patient}
      icon={
        <TaskRowDueDateIcon header={getDateMonthString(ticket.taskTicket.dueDate).toUpperCase()}>
          {ticket.taskTicket.dueDateAsNumber}
        </TaskRowDueDateIcon>
      }
      classes={classes}
      actions={
        <>
          <TaskStatus
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />

          <Actions
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        </>
      }
      summary={summary}
      createdByText={<TicketCreatedBy ticket={ticket} />}
      withPatientLink={withPatientLink}
    >
      {isExpanded && (
        <TicketCommunicationSection
          ticket={ticket}
          showContact={false}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      )}
    </TicketRenderer>
  );
};

export default observer(TaskTicketRow);
