import {
  TicketPatientContactModel,
  TicketPatientContactType
} from 'components/Ticket/TicketRow/TicketContact/TicketContact.types';

export function getTicketMessagesContactFormattedName<T extends TicketPatientContactType>(
  contact: TicketPatientContactModel<T>
): string {
  if (contact.type === TicketPatientContactType.Contact) {
    return `${contact.model.name} (${contact.model.relationship})`;
  }

  return `${contact.model.fullName} (Patient)`;
}
