// @ts-strict-ignore

import { SearchedEmrPatient, SearchedPatient } from 'fetchers/PatientsFetcher';
import { GlobalSearchPatientsResponse } from 'fetchers/responses/patient.response';

import { parseDate, parseDateTimeForInputField } from 'utils/DateUtils';

import { CmStatus } from 'models/CmPatient';
import { DxCode } from 'models/Conditions/SpecificCauseCondition';
import Patient from 'models/Patient';
import { PatientOptOut } from 'models/PatientOptOut';
import ScheduledProtocol, { ProtocolName } from 'models/ScheduledProtocol';
import UserModel from 'models/UserModel';

import ParseServerResponseService from './ParseServerResponseService';
import { UserParser } from './UserParser';

function parseProtocolInfo(protocolName: ProtocolName, code: number, info: any) {
  const parsedInfo: any = {};
  if (protocolName === ProtocolName.oralOncolytics) {
    Object.assign(parsedInfo, info);
    parsedInfo.fillFrequency = info.fill_frequency;
    parsedInfo.fillLocation = info.fill_location;
    parsedInfo.regimenId = info.regimen_id;
    parsedInfo.reportFrequency = info.report_frequency;
    parsedInfo.daysOfWeek = info.days_of_week;
  }

  if (protocolName === ProtocolName.drugSpecific) {
    parsedInfo.drugId = info.drugId;
    parsedInfo.timeBetweenReports = info.time_between_reports;
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_DATE) {
    parsedInfo.expirationDate = parseDate(info.expiration_date);
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_OCCURRENCES) {
    parsedInfo.occurrences = info.occurrences;
  }

  if (info.end_type === ScheduledProtocol.END_TYPE_DURATION) {
    parsedInfo.duration = info.duration;
    parsedInfo.durationUnit = info.durationUnit;
  }

  parsedInfo.startingDate = parseDateTimeForInputField(info.starting_date);
  parsedInfo.endType = info.end_type;
  parsedInfo.timeBetweenReports = info.time_between_reports;

  switch (code) {
    case ScheduledProtocol.PROTOCOL_CODE_MULTIPLE_WEEKLY:
      parsedInfo.daysOfWeek = info.days_of_week;
      break;

    case ScheduledProtocol.PROTOCOL_CODE_LINEAR:
      parsedInfo.occurrences = info.occurrences;
      break;
  }
  return parsedInfo;
}

function parseScheduledProtocol(unparsedProtocol: any) {
  const scheduledProtocol = new ScheduledProtocol();
  scheduledProtocol.id = unparsedProtocol.id;
  const info = parseProtocolInfo(
    unparsedProtocol.name,
    unparsedProtocol.code,
    unparsedProtocol.info
  );
  scheduledProtocol.info = info;
  scheduledProtocol.name = unparsedProtocol.name;
  scheduledProtocol.type = unparsedProtocol.type;
  scheduledProtocol.code = unparsedProtocol.code;
  scheduledProtocol.lastReportIntervalAt = parseDate(unparsedProtocol.lastReportIntervalAt);
  scheduledProtocol.patientId = unparsedProtocol.patientId;
  scheduledProtocol.timeZone = unparsedProtocol.timeZone;
  scheduledProtocol.operationMode = unparsedProtocol.operationMode;
  scheduledProtocol.automationMode = unparsedProtocol.automationMode;
  scheduledProtocol.reportProtocolTemplateId = unparsedProtocol.reportProtocolTemplateId ?? null;
  return scheduledProtocol;
}

export default class PatientParser {
  static parsePatientSearchResult(patientsSearchResults: GlobalSearchPatientsResponse): {
    patients: Patient[];
    hasMore: boolean;
  } {
    return {
      patients: patientsSearchResults.patients.map((searchedPatient) =>
        PatientParser.parseSearchedPatient(searchedPatient)
      ),
      hasMore: patientsSearchResults.hasMore
    };
  }

  static parseEmrSearchedPatient(patient: SearchedEmrPatient): Patient {
    const {
      mrn,
      firstName,
      lastName,
      emrPatientId,
      dob,
      phoneNumber,
      providerId,
      locationId,
      gender
    } = patient;
    const userModel = new UserModel(null, firstName, lastName);
    const patientModel = new Patient(userModel);
    patientModel.mrn = mrn;
    patientModel.emrPatientId = emrPatientId;
    patientModel.dateOfBirth = dob;
    patientModel.phone = phoneNumber;
    patientModel.providerId = providerId;
    patientModel.sex = gender;
    patientModel.location = locationId
      ? ParseServerResponseService.parseLocation({ id: patient.locationId })
      : null;

    return patientModel;
  }

  static parseSearchedPatient = (patient: SearchedPatient): Patient => {
    const { mrn, firstName, lastName, patientId, emrPatientId, dob, userId } = patient;
    const userModel = new UserModel(userId, firstName, lastName);
    const patientModel = new Patient(userModel);
    patientModel.mrn = mrn;
    patientModel.id = patientId;
    patientModel.emrPatientId = emrPatientId;
    patientModel.dateOfBirth = dob;

    return patientModel;
  };

  static parsePatient = (patient: any): Patient => {
    const userModel = UserParser.parseUser(patient.user);
    const patientModel = new Patient(userModel);
    patientModel.id = patient.id;
    patientModel.lastReportAckTime = patient.lastReportAckTime;
    patientModel.lastReportNameRequested = patient.lastReportNameRequested;
    patientModel.lastReportTime = patient.lastReportTime;
    patientModel.phone = patient.user.phone;
    patientModel.lastSmsRequestReportTime = parseDate(patient.lastSmsRequestReportTime);
    patientModel.protocolOverdueStartTime = parseDate(patient.protocolOverdueStartTime);
    patientModel.cmStatus = new CmStatus(patient.cmStatus);
    patientModel.lastAutomatedRequestReportTime = parseDate(patient.lastAutomatedRequestReportTime);
    patientModel.requestCallAttempts = patient.requestCallAttempts;
    patientModel.lastSnoozeTime = parseDate(patient.lastSnoozeTime);
    patientModel.tags = patient.tagIds;
    patientModel.mrn = patient.mrn;
    patientModel.status = patient.status;
    patientModel.locale = patient.locale;
    patientModel.createdAt = patient.createdAt;
    patientModel.updatedAt = patient.updatedAt;
    patientModel.callbackReason = patient.callbackReason;
    patientModel.remoteMonitoringConsent = parseDate(patient.remoteMonitoringConsent);
    patientModel.messagingConsent = parseDate(patient.messagingConsent);
    patientModel.sourceId = patient.sourceId;
    patientModel.enrollmentStatus = patient.enrollmentStatus;
    patientModel.lastTicketCreatedAt = patient.lastTicketCreatedAt;
    patientModel.isLagging = Boolean(patient.isLagging);
    patientModel.isDeceased = Boolean(patient.isDeceased); //work-queue v2 -> isDeceased
    patientModel.location = patient.location
      ? ParseServerResponseService.parseLocation(patient.location)
      : null;
    patientModel.sex = patient.birthSex;
    patientModel.dateOfBirth = patient.dateOfBirth;

    patientModel.optOut = patient.patientOptOut
      ? PatientOptOut.fromJson(patient.patientOptOut)
      : null;
    patientModel.activatedAt = patient.activatedAt;
    patientModel.financialAssistance = Boolean(patient.financialAssistance);
    patientModel.emrPatientId = patient.emrPatientId;
    patientModel.providerId = patient.providerId;
    patientModel.noResponse = patient.noResponse;

    const unparsedContacts = Array.isArray(patient.patientContacts) ? patient.patientContacts : [];
    patientModel.contacts = unparsedContacts.map((contact: any) =>
      ParseServerResponseService.parsePatientContact(contact)
    );

    if (Array.isArray(patient.questionnairesAnswers)) {
      patientModel.questionnairesAnswers = patient.questionnairesAnswers.map(
        ParseServerResponseService.parseReport
      );
    }
    if (Array.isArray(patient.scheduledProtocols)) {
      patientModel.scheduledProtocols = patient.scheduledProtocols.map(parseScheduledProtocol);
    }

    if (patient.dxCodes?.length) {
      const codes: DxCode[] = patient.dxCodes.map((codeItem: DxCode): DxCode => {
        return {
          code: codeItem.code,
          text: codeItem.text
        };
      });
      patientModel.dxCodesMap.setItems(codes);
    }

    patientModel.hasTicketWithMessage = patient.hasTicketWithMessage;
    return patientModel;
  };
}
